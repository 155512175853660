.auctionImage {
  max-width: 100%;
  object-fit: contain;
  max-height: inherit;
}

.auctionCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
