@import '~antd/dist/antd.css';
@import '~@ant-design/pro-table/dist/table.css';
@import '~@ant-design/pro-form/dist/form.css';
@import '~@ant-design/pro-field/dist/field.css';

body {
  font-family: 'Apex Rounded', sans-serif;
}

.ant-layout {
  background-color: var(--global-color-bg);
}

.ant-layout-header,
.ant-layout-footer,
.ant-layout-sider {
  color: #fff;
}
.ant-layout-header {
  background: #040404;
}

.ant-layout-footer {
  background-color: var(--global-color-secondary);
}

.ant-layout-sider {
  background: #040404;
}

.ant-layout-footer {
  line-height: 1.5;
}

.ant-menu.ant-menu-dark {
  background: #040404;
}

.ant-menu.ant-menu-dark .ant-menu-item.ant-menu-item-selected {
  background-color: var(--global-color-primary);
}

.ant-menu.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: #171717;
}

.ant-layout-content {
  min-height: calc(100vh - 100px);
  color: #333333;
  /* line-height: 120px; */
  background-color: var(--global-color-bg);
}

/* HEIGHT & WEIGHT */
.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-10 {
  width: 10%;
}

/* PADDING */
.p-1 {
  padding: 8px;
}

.p-2 {
  padding: 16px;
}

.p-3 {
  padding: 24px;
}

.pt-1 {
  padding-top: 8px;
}

.pt-2 {
  padding-top: 16px;
}

.pt-3 {
  padding-top: 24px;
}

.pt-4 {
  padding-top: 32px;
}

.pb-2 {
  padding-bottom: 16px;
}

.pb-3 {
  padding-bottom: 24px;
}

.pr-3 {
  padding-right: 24px;
}

.px-2 {
  padding-right: 16px;
  padding-left: 16px;
}

.pl-2 {
  padding-left: 16px;
}

.px-1 {
  padding-right: 8px;
  padding-left: 8px;
}

.px-1 {
  padding-right: 16px;
  padding-left: 16px;
}

.px-3 {
  padding-right: 24px;
  padding-left: 24px;
}

.py-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-3 {
  padding-top: 24px;
  padding-bottom: 24px;
}

/* MARGIN */
.m-0 {
  margin: 0px !important;
}

.my-1 {
  margin: 8px 0px;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 24px;
}
.mb-1 {
  margin-bottom: 8px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-3 {
  margin-bottom: 24px;
}

.mr-1 {
  margin-right: 8px !important;
}

.my-3 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

/* BUTTONS */
.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.btn-primary,
.btn-primary:hover,
.btn-primary:focus {
  background-color: var(--global-color-primary);
  border-color: var(--global-color-primary);
}


.ant-btn-group .ant-btn-primary:last-child:not(:first-child), .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #fff;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #fff;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  background-color: var(--global-color-secondary);
  border-color: var(--global-color-secondary);
}

/* TEXT COLORS */

.text-white {
  color: white !important;
}

.text-primary {
  color: var(--global-color-primary) !important;
}

.text-secondary {
  color: var(--global-color-secondary) !important;
}

/* BORDER COLORS */

.border-primary {
  border-color: var(--global-color-primary) !important;
}

.border-secondary {
  border-color: var(--global-color-secondary) !important;
}

/* FLOAT */

.fl-r {
  float: right;
}

/* FONTS  */

.fs-8 {
  font-size: 64px !important;
}

/* OVERFLOW */

.o-hidden {
  overflow: hidden;
}

/* CONTAINERS */

.container-sm {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  max-width: 578px;
}

.container {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}

.container-xl {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1400px;
}

.container-xxl {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1600px;
}

/* Display */

.d-flex {
  display: flex;
  flex-direction: column;
}

.d-block {
  display: inline-block;
}

.d-none {
  display: none;
}

/* Align */

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}
