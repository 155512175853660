.logoContainer {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.menuContainer {
  flex: 1;
}
