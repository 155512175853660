.walletContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.walletInnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.walletCard {
  width: 300px;
  margin: 0.75rem;
}

.walletButton {
  opacity: 0.5;
}

.walletButton:hover {
  opacity: 1;
}
