.container {
  min-height: 64px;
}

@media (max-width: 575px) {
  .container {
    padding: 0 15px;
  }
}

.logo {
  display: inline;
  max-width: 200px;
}
