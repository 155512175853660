.contentRow {
  min-height: 500px;
  padding: 50px 0px;
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, var(--global-color-bg) 80%); */
}

.shareContainer {
  height: 50px;
  margin-top: -25px;
  padding: 0px 24px;
}

.detailsRow {
  padding: 24px;
}
