.logoContainer {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
}

.header {
  padding: 0;
  z-index: 20;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
