.container {
  min-height: 100px;
  box-shadow: rgb(0 0 0 / 5%) 0px 10px 20px;
  background-color: white;
}

@media (max-width: 575px) {
  .usdPrice {
    text-align: start;
  }
}

.infoIcon {
  font-size: 16px;
}
