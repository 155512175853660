@media (max-width: 575px) {
  .wallet > span:last-child {
    display: none;
  }
}

.wallet,
.wallet:hover,
.wallet:focus {
  background-color: #95107f;
  border-color: #95107f;
  font-family: 'Apex Rounded', sans-serif;
  font-weight: 500;
}

.walletConnected,
.walletConnected:hover {
  background-color: #b7eb8f;
  border-color: darkgreen;
  color: darkgreen;
}

.buttonIcon {
  font-size: 20px;
}

.avatarContainer {
  position: relative;
  cursor: pointer;
  font-size: 18px;
}

.avatarContainer::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 15px;
}

.avatarInnerContainer {
  width: 150px;
  height: 80%;
  max-height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 30px;
}

.avatarSubSection {
  position: absolute;
  left: 0;
  top: 50px;
  width: 150px;
  background-color: white;
  visibility: hidden;
  opacity: 0;
  transition: all 200ms ease-in-out;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 0.5rem;
  z-index: 10;
}

.avatarContainer:hover .avatarSubSection {
  visibility: visible;
  opacity: 1;
}

.avatarSubSectionBtn {
  width: 100%;
  outline: 0;
  border: 0;
  color: black;
  background: transparent;
  height: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 1rem 0;
  transition: background-color 150ms ease-in-out;
}

.avatarSubSectionBtn:hover {
  background-color: rgb(221, 221, 221);
}
