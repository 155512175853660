.footerFixed {
  text-align: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}

.footer {
  text-align: center;
}

.text {
  color: #9c9c9c;
}

.link {
  color: #636363 !important;
}

.link:hover {
  border-bottom: 1px dotted rgba(255, 255, 255, 0.56);
  color: #fff !important;
}
