.textSkeleton {
  width: 100%;
  margin-bottom: 12px;
}

.imageSkeleton {
  margin: auto;
  width: 100%;
}

.creativeContainer {
  max-height: 50vh;
}
