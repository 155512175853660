.gridContainer {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  align-items: stretch;
  grid-gap: 12px;
}

.noAuctionsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .gridContainer {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
  }
}
@media (min-width: 992px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 18px;
  }
}

@media (min-width: 1200px) {
  .gridContainer {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }
}

.noAuctionsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
