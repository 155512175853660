.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85);
}

h5.text {
  font-weight: normal;
  margin-bottom: 0;
}

h5.text.activeItem {
  font-weight: bold;
}

h5.text:hover {
  font-weight: bold;
}
