.container {
  height: 100vh;
}

.main {
  overflow-y: auto;
}

.contentContainer {
  min-height: auto;
  flex: 1;
}

.loadinContainer {
  height: 100vh;
  width: 100vw;
}
