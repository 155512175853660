.textInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-left: -1;
}

.uploadButton {
  margin-right: -1;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
