.container {
  display: flex;
  flex-direction: column;
}

.playerRow {
  height: 360px;
}

.infoRow {
  background-color: white;
  flex: 1;
}

.infoCol {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
} 

.priceRow {
  background-color: black;
}

.closing {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
