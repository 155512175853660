.closing {
  animation: blinker 1s linear infinite;
}

.closingText {
  text-align: 'center';
  vertical-align: 'middle';
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}