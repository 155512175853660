.winnersContainer {
  overflow-y: auto;
}

@media (max-width: 575px) {
  .winnersContainer {
    max-height: 700px;
  }
}
@media (min-width: 576px) {
  .winnersContainer {
    max-height: 600px;
  }
}
